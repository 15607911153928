import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiSettingsService, ApiToken } from '../api-settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-token-dialog',
  templateUrl: './create-token-dialog.component.html',
  styleUrls: ['./create-token-dialog.component.scss']
})
export class CreateTokenDialogComponent {
  form = new FormGroup({
    name: new FormControl('', Validators.required)
  });

  createdToken: ApiToken | null = null;
  copied = false;
  isLoading = false;

  public tokenCreated$ = new Subject<ApiToken>();

  constructor(
    private dialogRef: MatDialogRef<CreateTokenDialogComponent>,
    private apiSettingsService: ApiSettingsService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  onCreate() {
    if (this.form.valid) {
      this.isLoading = true;
      const name = this.form.value.name;
      this.apiSettingsService.createToken(name).subscribe({
        next: (token: ApiToken) => {
          this.createdToken = token;
          this.toastr.success(this.translate.instant('settings.api.tokenCreatedSuccess'));
          this.tokenCreated$.next(token);
          this.isLoading = false;
        },
        error: () => {
          this.toastr.error(this.translate.instant('settings.api.tokenCreationError'));
          this.isLoading = false;
        }
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  copyTokenToClipboard() {
    if (this.createdToken && !this.copied) {
      navigator.clipboard.writeText(this.createdToken.token);
      this.toastr.success(this.translate.instant('settings.api.copySuccess'));
      this.copied = true;
    }
  }
}
