<mat-toolbar class="header">
  <span>{{ 'footers.new' | translate }}</span>
</mat-toolbar>

<div class="signature-create-content">
  <form [formGroup]="form" class="form" (ngSubmit)="save()">
    <mat-form-field appearance="fill" class="name-form-field">
      <mat-label>{{ 'footers.name' | translate }}</mat-label>
      <input matInput formControlName="name" type="text">
      <mat-error *ngIf="form.hasError('required', ['name'])">{{ 'fieldRequired' | translate }}</mat-error>
    </mat-form-field>

    <angular-editor matInput formControlName="content" [config]="config"></angular-editor>

    <div class="dynamic-menu">
      <div class="menu-buttons">
        <button mat-raised-button type="button" (click)="toggleSection('addons')">
          <mat-icon>add_box</mat-icon> {{ 'footers.addons' | translate }}
        </button>
        <button mat-raised-button type="button" (click)="toggleSection('dynamicVariables')">
          <mat-icon>tune</mat-icon> {{ 'footers.dynamicVariables' | translate }}
        </button>
      </div>
    
      <div class="menu-content">
        <div class="section" [class.hidden]="activeSection !== 'addons'">
          <app-addons *ngIf="addonsLoaded" [form]="form"></app-addons>
        </div>
  
        <div class="section" [class.hidden]="activeSection !== 'dynamicVariables'">
          <div class="dynamic">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'footers.userAvatarWidth' | translate }}</mat-label>
              <input matInput formControlName="userAvatarWidth" type="number" min="0">
              <span matSuffix>px</span>
            </mat-form-field>
      
            <mat-form-field appearance="fill">
              <mat-label>{{ 'footers.userAvatarBorderRadius' | translate }}</mat-label>
              <input matInput formControlName="userAvatarBorderRadius" type="number" min="0" max="50">
              <span matSuffix>%</span>
            </mat-form-field>
  
            <mat-form-field appearance="fill">
              <mat-label>{{ 'footers.phoneLabel' | translate }}</mat-label>
              <input matInput formControlName="phoneLabel" type="text">
            </mat-form-field>
  
            <mat-form-field appearance="fill">
              <mat-label>{{ 'footers.mobileLabel' | translate }}</mat-label>
              <input matInput formControlName="mobileLabel" type="text">
            </mat-form-field>
  
            <mat-form-field appearance="fill">
              <mat-label>{{ 'footers.emailLabel' | translate }}</mat-label>
              <input matInput formControlName="emailLabel" type="text">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <button mat-raised-button (click)="goToSignatures()">{{ 'cancel' | translate }}</button>
      <button mat-stroked-button color="primary" (click)="saveAndInstall()"
              [disabled]="!form.valid || setting">
        {{ setting ? 'saving' : 'saveAndInstall' | translate }}
      </button>
      <button mat-raised-button type="submit" [disabled]="!form.valid || setting">
        {{ setting ? 'saving' : 'save' | translate }}
      </button>
    </div>

    <app-placeholders></app-placeholders>
  </form>

  <aside class="predefined-wrapper">
    <h2>{{ 'footers.predefined' | translate }}</h2>

    <ul class="signature-list" *ngIf="predefined$ | async as predefined">
      <li *ngFor="let signature of predefined" class="signature-list-item">

        <app-signature-predefined-item [signature]="signature">
          <button
            [matTooltip]="'predefined.save' | translate"
            mat-icon-button (click)="load(signature)">
            <mat-icon title="{{'save' | translate}}">download</mat-icon>
          </button>
        </app-signature-predefined-item>
    </ul>
  </aside>

</div>
