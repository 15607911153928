import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from '../shared/shared.module';
import {SignaturesEffects} from './signatures.effects';
import {SignaturesService} from './signatures.service';
import {signaturesReducer} from './signatures.reducer';
import {SignaturesComponent} from './signatures.component';
import {RouterModule} from '@angular/router';
import {SignaturePredefinedItemComponent } from './signature-predefined-item/signature-predefined-item.component';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {SetForAllDialogComponent} from './signature-details/set-for-all-dialog/set-for-all-dialog.component';
import {FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SignaturesComponent,
    SignaturePredefinedItemComponent,
    SetForAllDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    StoreModule.forFeature('signatures', signaturesReducer),
    EffectsModule.forFeature([SignaturesEffects]),
    CodemirrorModule,
  ],
  providers: [SignaturesService],
  exports: [
    SignaturesComponent,
    SignaturePredefinedItemComponent
  ]
})
export class SignaturesModule {
}
