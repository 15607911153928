import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddClientRequest, ClientWithStats, EditClientRequest } from './client.interface';
import { endOfDay } from '../shared/utils/trial.util';
import { ClientMonthlyStats } from './client.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) {
  }

  getAll = () => this.http.get<ClientWithStats[]>('/admin/client');

  add = (request: AddClientRequest) => this.http.post('/admin/client', {...request, trialFinishDate: endOfDay(request.trialFinishDate)});

  remove = (domain: string) => this.http.delete(`/admin/client/${encodeURIComponent(domain)}`);

  edit = (domain: string, request: EditClientRequest) =>
    this.http.put(`/admin/client/${domain}`, {...request, trialFinishDate: endOfDay(request.trialFinishDate)})

  downloadBilling = () => this.http.get<ClientMonthlyStats[]>('/client-monthly-stats');
}
