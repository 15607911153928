import { Component, OnInit } from '@angular/core';
import { LoaderService } from './shared/loading/loader.service';
import { AuthService } from './auth/auth.service';
import { environment } from '../environments/environment';
import Clarity from '@microsoft/clarity';

export const LANG = 'lang';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public loading: LoaderService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    Clarity.init(environment.clarityProjectId);
  }
}
