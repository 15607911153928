import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User as FirebaseUser } from '@angular/fire/auth';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getCurrentAccount } from '../accounts/accounts.reducer';
import { ClientMonthlyStats } from '../settings/settings.interface';
import { Store } from '@ngrx/store';
import { BillingSettings } from '../settings/settings.interface';
import { BillingSettingsService } from './billing-settings.service';
import { GetBillingSettingsAction, ChangeBillingSettingsAction } from '../settings/settings.actions';
import { getBillingSettingsSelector } from '../settings/settings.reducer';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.scss']
})
export class BillingSettingsComponent implements OnInit {
  user: Observable<FirebaseUser>;
  account$: Observable<string>;
  domain: string;
  monthlyStats: ClientMonthlyStats[] = [];
  displayedColumns: string[] = ['date', 'numberOfEmployees', 'price'];
  plans = ['gSignature Basic', 'gSignature Plus', 'gSignature Pro'];
  currencies = ['USD', 'EUR', 'PLN'];

  form = new UntypedFormGroup({
    planName: new UntypedFormControl({ value: '', disabled: true }),
    price: new UntypedFormControl({ value: '', disabled: true }, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
    currency: new UntypedFormControl({ value: '', disabled: true }),
    planId: new UntypedFormControl({ value: '', disabled: true }),
    subscriptionStartDate: new UntypedFormControl({ value: '', disabled: true }),
    lastInvoiceUserCount: new UntypedFormControl({ value: '', disabled: true }),
    currentUserCount: new UntypedFormControl({ value: '', disabled: true }),
    contactName: new UntypedFormControl(''),
    contactPhone: new UntypedFormControl(''),
    contactEmail: new UntypedFormControl(''),
    companyName: new UntypedFormControl(''),
    country: new UntypedFormControl(''),
    street: new UntypedFormControl(''),
    streetNumber: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    postalCode: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    vatNumber: new UntypedFormControl(''),
    paymentMethod: new UntypedFormControl('invoice')
  });

  constructor(
    private billingSettingsService: BillingSettingsService,
    private store: Store,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.auth.authState$;
    this.account$ = this.store.select(getCurrentAccount);
  
    if (this.auth.isSuperAdmin) {
      this.enableFieldsForSuperAdmin();
    }
  
    this.store.dispatch(new GetBillingSettingsAction());
    this.store.select(getBillingSettingsSelector)
    .subscribe((billingSettings: BillingSettings) => {
      if (billingSettings) {
        this.fillForm(billingSettings);
      }
    });
  
    this.account$.subscribe(account => {
      this.domain = account;
  
      if (this.domain) {
        this.billingSettingsService.getMonthlyStats(this.domain).subscribe(stats => {
          this.monthlyStats = stats;
        });
      }
    });
  }

  fillForm = (billingSettings: BillingSettings) => {
    this.form.patchValue({
      planName: billingSettings.planName,
      price: billingSettings.price,
      currency: billingSettings.currency,
      planId: billingSettings.planId,
      subscriptionStartDate: new Date(billingSettings.subscriptionStartDate).toISOString(),
      lastInvoiceUserCount: billingSettings.lastInvoiceUserCount,
      currentUserCount: billingSettings.currentUserCount,
      contactName: billingSettings.contactName,
      contactPhone: billingSettings.contactPhone,
      contactEmail: billingSettings.contactEmail,
      companyName: billingSettings.companyName,
      country: billingSettings.country,
      street: billingSettings.street,
      streetNumber: billingSettings.streetNumber,
      city: billingSettings.city,
      postalCode: billingSettings.postalCode,
      state: billingSettings.state,
      vatNumber: billingSettings.vatNumber,
      paymentMethod: billingSettings.paymentMethod,
    });
  };

  enableFieldsForSuperAdmin() {
    ['planName', 'price', 'currency', 'subscriptionStartDate'].forEach(field => this.form.get(field)?.enable());
  }

  onSubmit = () => {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
  
      if (formValue.subscriptionStartDate instanceof Date) {
        formValue.subscriptionStartDate = formValue.subscriptionStartDate.toISOString();
      }
  
      this.store.dispatch(new ChangeBillingSettingsAction(formValue));
    } else {
      this.form.markAllAsTouched();
    }
  };
}
