<h1 mat-dialog-title>{{ 'footers.dialog.selectOption' | translate }}</h1>
<div mat-dialog-content>
  <div class="dialog-content">
    <mat-radio-group [(ngModel)]="selectedOption" class="radio-group">
      <mat-radio-button *ngFor="let option of options" [value]="option.value" class="radio-button">
        {{ option.label | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="dialog-actions">
    <button mat-button (click)="onNoClick()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button color="primary" (click)="confirmSelection()">
      {{ 'set' | translate }}
    </button>
  </div>
</div>

