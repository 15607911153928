<nav mat-tab-nav-bar mat-stretch-tabs="false">
  <a mat-tab-link
     routerLinkActive
     #rla1="routerLinkActive"
     [active]="rla1.isActive"
     routerLink="company">{{ 'settings.companyData' | translate }}</a>

  <a mat-tab-link
     routerLinkActive
     #rla2="routerLinkActive"
     [active]="rla2.isActive"
     routerLink="employees">{{ 'settings.employeeData' | translate }}</a>

  <a mat-tab-link
     routerLinkActive
     #rla3="routerLinkActive"
     [active]="rla3.isActive"
     routerLink="advanced">{{ 'settings.advanced' | translate }}</a>

  <a mat-tab-link
     routerLinkActive
     #rla4="routerLinkActive"
     [active]="rla4.isActive"
     routerLink="integration">{{ 'settings.integration.header' | translate }}</a>

   <a mat-tab-link
      routerLinkActive
      #rla5="routerLinkActive"
      [active]="rla5.isActive"
      routerLink="billing">{{ 'settings.billing.header' | translate }}</a>

   <a mat-tab-link
      routerLinkActive
      #rla6="routerLinkActive"
      [active]="rla6.isActive"
      routerLink="api">{{ 'settings.api.header' | translate }}</a>
</nav>

<router-outlet></router-outlet>
