<div class="data-import-container">
  <h1 mat-dialog-title>{{ 'employee.localImport.header' | translate }}</h1>

  <mat-dialog-content>
    <p>{{ 'employee.localImport.instructions' | translate }}</p>

    <h3>{{ 'employee.import.mandatoryColumns' | translate }}</h3>
    <ul>
      <li><code>email</code>: {{ 'employee.localImport.mandatoryEmail' | translate }}</li>
      <li><code>givenName</code>: {{ 'employee.localImport.mandatoryGivenName' | translate }}</li>
      <li><code>familyName</code>: {{ 'employee.localImport.mandatoryFamilyName' | translate }}</li>
    </ul>

    <h3>{{ 'employee.import.optionalColumns' | translate }}</h3>
    <ul>
      <li><code>phoneNumber</code>: {{ 'employee.localImport.optionalPhoneNumber' | translate }}</li>
      <li><code>jobTitle</code>: {{ 'employee.localImport.optionalJobTitle' | translate }}</li>
    </ul>

    <input type="file" class="file-input" (change)="onFileSelected($event)" accept=".csv" />
    <div class="file-upload">
      <button mat-stroked-button color="primary" (click)="triggerFileInput()">
        {{ 'employee.localImport.selectFile' | translate }}
        <mat-icon>attach_file</mat-icon>
      </button>
      <h6>{{ fileName || ('employee.localImport.noFileYet' | translate) }}</h6>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button color="primary" [disabled]="!selectedFile" (click)="onImport()">
      {{ 'employee.localImport.import' | translate }}
    </button>
  </div>
</div>
