import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BillingSettings } from '../settings/settings.interface';
import { ClientMonthlyStats } from '../settings/settings.interface';

@Injectable({
  providedIn: 'root'
})
export class BillingSettingsService {

  constructor(private http: HttpClient) { }

  get(): Observable<BillingSettings> {
    return this.http.get<BillingSettings>('/billing-settings');
  }

  update(payload: BillingSettings): Observable<any> {
    return this.http.post('/billing-settings', payload);
  }

  getMonthlyStats(domain: string,): Observable<ClientMonthlyStats[]> {
    return this.http.get<ClientMonthlyStats[]>(`/client-monthly-stats/${domain}`);
  }
}
