import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SetForAll } from '../../signatures.interface';

@Component({
  selector: 'app-set-for-all-dialog',
  templateUrl: './set-for-all-dialog.component.html',
  styleUrls: ['./set-for-all-dialog.component.scss'],
})
export class SetForAllDialogComponent {
  options = [
    { value: SetForAll.EMPLOYEES, label: 'footers.dialog.options.employees' },
    { value: SetForAll.ALIASES, label: 'footers.dialog.options.aliases' },
    { value: SetForAll.EMPLOYEES_AND_ALIASES, label: 'footers.dialog.options.employeesAndAliases' },
  ];
  selectedOption: string;

  constructor(
    public dialogRef: MatDialogRef<SetForAllDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedOption = data.selectedOption;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmSelection(): void {
    this.dialogRef.close(this.selectedOption);
  }
}
