<div class="clients">
  <div class="clients-header">
    <form [formGroup]="form" class="form" (ngSubmit)="add()">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'client.domain' | translate }}</mat-label>
        <input matInput formControlName="domain" type="text">
        <mat-error *ngIf="form.hasError('required', ['domain'])">{{ 'fieldRequired' | translate }}</mat-error>
        <mat-error *ngIf="form.hasError('httpExists', ['domain'])">{{ 'domainWithoutHttp' | translate }}</mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'client.googleAdminEmail' | translate }}</mat-label>
        <input matInput formControlName="googleAdminEmail" type="text">
        <mat-error *ngIf="form.hasError('required', ['googleAdminEmail'])">{{ 'fieldRequired' | translate }}</mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{'client.trialFinishDate' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="trialFinishDate">
        <mat-hint>YYYY-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="form.hasError('required', ['trialFinishDate'])">{{'fieldRequired' | translate}}</mat-error>
        <mat-error *ngIf="form.hasError('dateInvalid', ['trialFinishDate'])">{{'futureDateInvalid' | translate}}</mat-error>
      </mat-form-field>
  
      <mat-checkbox formControlName="isIncludedDemoContent">{{ 'client.includeDemoContent' | translate }}</mat-checkbox>
  
      <button mat-raised-button type="submit" [disabled]="saving" [class.loading-button]="saving">
        <mat-spinner diameter="20" *ngIf="saving"></mat-spinner>
        <span *ngIf="!saving">{{ 'save' | translate }}</span>
      </button>
    </form>
  
    <button mat-raised-button type="button" (click)="downloadBilling()" [class.loading-button]="billing" [disabled]="billing">
      <mat-spinner diameter="20" *ngIf="billing"></mat-spinner>
      <span *ngIf="!billing">{{ 'client.downloadBilling' | translate }}</span>
    </button>
  </div>

  <app-client-table [data]="clients$" [actionsTemplate]="actions"></app-client-table>

  <ng-template #actions let-client="client">
    <div class="actions-wrapper">
      <button class="action-edit" mat-icon-button (click)="edit(client)"
              [matTooltip]="'client.editTooltip' | translate">
        <mat-icon>edit</mat-icon>
      </button>
      <button class="action-delete" mat-icon-button (click)="remove(client.domain)"
              [matTooltip]="'client.deleteTooltip' | translate">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </ng-template>

</div>
