import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ApiToken {
  id: string;
  name: string;
  token: string;
  createdAt: string;
  expiresAt: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiSettingsService {
  private baseUrl = '/api-settings';

  constructor(private http: HttpClient) { }

  getTokens(): Observable<ApiToken[]> {
    return this.http.get<ApiToken[]>(`${this.baseUrl}/tokens`);
  }

  createToken(name: string): Observable<ApiToken> {
    return this.http.post<ApiToken>(`${this.baseUrl}/tokens`, { name });
  }

  deleteToken(tokenId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/tokens/${tokenId}`);
  }
}
