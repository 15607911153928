<div class="api-settings">
  <div class="section-label">
    {{ 'settings.api.yourToken' | translate }}
  </div>

  <mat-divider></mat-divider>

  <app-loading *ngIf="isLoading"></app-loading>

  <div *ngIf="!isLoading && tokens.length > 0" class="token-list">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'settings.api.tokenName' | translate }}</th>
        <td mat-cell *matCellDef="let token">{{ token.name }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>{{ 'settings.api.createdAt' | translate }}</th>
        <td mat-cell *matCellDef="let token">{{ token.createdAt | localizedDate:'medium' }}</td>
      </ng-container>

      <ng-container matColumnDef="expiresAt">
        <th mat-header-cell *matHeaderCellDef>{{ 'settings.api.expiresAt' | translate }}</th>
        <td mat-cell *matCellDef="let token">{{ token.expiresAt | localizedDate:'medium' }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'settings.api.status' | translate }}</th>
        <td mat-cell *matCellDef="let token">
          <span *ngIf="!token.isExpired" class="status-active">
            {{ 'settings.api.active' | translate }}
          </span>
          <span *ngIf="token.isExpired" class="status-expired">
            {{ 'settings.api.expired' | translate }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
        <td mat-cell *matCellDef="let token">
          <button mat-button color="warn" (click)="deleteToken(token.id)">
            {{ 'delete' | translate }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div>
    <button class="api-create" mat-raised-button color="primary" (click)="openCreateTokenDialog()">
      {{ 'settings.api.createToken' | translate }}
    </button>
  </div>
</div>
