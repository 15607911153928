import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Group, Unit, User} from './user';

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private http: HttpClient) {
  }

  current = () => this.http.get<User>('/current-user');
  updateAll = (body: any) => this.http.post('/footer', body);
  units = () => this.http.get<Unit[]>('/footer/unit');
  groups = () => this.http.get<Group[]>('/footer/group');

  toggleDefaultInstallationForGroup = (groupKey: string, signatureTemplateId: string, defaultInstallationEnabled: boolean) => {
    return this.http.put(`/organisation-groups/${groupKey}/default-signature`, {
      groupKey,
      signatureTemplateId,
      defaultInstallationEnabled
    });
  };
}
