import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '../users/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Group} from '../users/user';
import {MatDialog} from '@angular/material/dialog';
import {
  ConfirmDefaultSignatureForGroupMembersDialogComponent
} from './confirm-default-signature-for-group-members-dialog/confirm-default-signature-for-group-members-dialog.component';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  displayedColumns = ['expand', 'name', 'email', 'directMembersCount', 'defaultForEmployees', 'assign'];
  dataSource: MatTableDataSource<Group>;
  expandedGroupId: string | null = null;
  members: string[] = [];
  @Input() updating ? = false;
  @Input() currentSignatureId: string;
  @Input() signatureName!: string;
  @Output() clicked = new EventEmitter<string>();
  @Output() clickedAll = new EventEmitter<{ group: string, alias: string[] }>();

  @ViewChild(MatSort) sort: MatSort;

  checkedDefaultInstallationGroups = new Set<string>();
  isLoading: boolean = false;
  isDataLoaded: boolean = false;

  constructor(private userService: UserService,
              private dialog: MatDialog,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
  }

  loadData() {
    if (this.isDataLoaded) {
      return;
    }

    this.isLoading = true;
    this.fetchGroups();
  }

  private fetchGroups() {
    this.userService.groups().subscribe(groups => {
      this.dataSource.data = groups;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
      this.isDataLoaded = true;
      this.checkedDefaultInstallationGroups = new Set(groups.filter(group => group.signatureTemplateId === this.currentSignatureId).map(group => group.id));
    });
  }

  public isDefaultGroupInstallationSelected(signatureId: string): boolean {
    return this.checkedDefaultInstallationGroups.has(signatureId);
  }

  assign(group: string) {
    return this.clicked.emit(group);
  }

  assignToAll(group: string, alias: string) {
    return this.clickedAll.emit({group, alias: [alias]});
  }

  toggleMembers(groupId: string) {
    if (this.expandedGroupId === groupId) {
      this.expandedGroupId = null;
      this.members = [];
    } else {
      this.expandedGroupId = groupId;
      const selectedGroup = this.dataSource.data.find(group => group.id === groupId);
      this.members = selectedGroup ? selectedGroup.members : [];
    }
  }

  toggleDefaultInstallation(event: MatSlideToggleChange, group: Group) {
    event.source.checked = !event.source.checked; // prevent toggle, as we will do it manually after user confirmation
    const params = {
      template: this.signatureName,
      group: group.name
    };

    if (this.checkedDefaultInstallationGroups.has(group.id)) {
      this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('signature-group.dialog.removeDescription', params)}})
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) {
            this.checkedDefaultInstallationGroups.delete(group.id);
            event.source.checked = false;
            this.toggleInstallationAfterUserConfirmation(group, false);
          }
        });
      return;
    }

    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('signature-group.dialog.description', params)}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.checkedDefaultInstallationGroups.add(group.id);
            event.source.checked = true;
            this.toggleInstallationAfterUserConfirmation(group, true);
          }
        }
      );
  }

  private toggleInstallationAfterUserConfirmation(group: Group, installed: boolean) {
    this.userService.toggleDefaultInstallationForGroup(group.email, this.currentSignatureId, installed).subscribe(res => {
      this.fetchGroups();
    });
  }
}
