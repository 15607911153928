<mat-toolbar class="header">
  <span>{{ footer?.name }}</span>
  <div class="actions">
    <a mat-raised-button [routerLink]="['edit']">
      {{ 'footers.edit' | translate }}
    </a>

    <a mat-raised-button (click)="setForAll()" [disabled]="updating">
      {{ (updating ? 'footers.setting' : 'footers.setAll') | translate }}
    </a>
  </div>
</mat-toolbar>

<div class="signature-details-content">
  <div *ngIf="footer?.modifiedAt" class="modify-date">
    {{ 'modifiedAt' | translate }}: {{ footer.modifiedAt | localizedDate:'medium' }}
  </div>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ 'footers.preview' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-loading *ngIf="!preview"></app-loading>
      <iframe #iframePreview class="w-100"
              onload='(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'>
      </iframe>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ 'footers.setForUser' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-submit-user
        #submitUserComponent [updating]="updating"
        (submitted)="setForUser($event)">
      </app-submit-user>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ 'footers.setForMe' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button mat-raised-button [disabled]="updating"
              (click)="setForMe()">{{ 'footers.setForMe' | translate }}
      </button>
    </mat-card-content>
  </mat-card>

  <div class="mat-accordion-wrapper">
    <mat-accordion>
      <mat-expansion-panel (opened)="unitComponent.loadData()">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'footers.setForUnit' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-unit #unitComponent
                  [updating]="updating"
                  (clicked)="assignToUnit($event)">
        </app-unit>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="mat-accordion-wrapper">
    <mat-accordion>
      <mat-expansion-panel (opened)="groupComponent.loadData()">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'footers.setForGroup' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          <app-group #groupComponent
                     [updating]="updating"
                     [currentSignatureId]="footer?.id"
                     (clicked)="assignToGroup($event)"
                     (clickedAll)="assignToGroupWithAlias($event)"
                     [signatureName]="footer?.name"
          ></app-group>
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="actions">
  <button mat-raised-button (click)="goToSignatures()">
    {{ 'cancel' | translate }}
  </button>
  <a
    *ngIf="!(footer?.numberOfInstallations > 0 || footer?.numberOfAliases > 0)"
    mat-raised-button color="warn"
    (click)="delete()"
    [disabled]="deleting">
    {{ (deleting ? 'deleting' : 'delete') | translate }}
  </a>
</div>

<br/><br/><br/><br/><br/><br/>
