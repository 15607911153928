import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SignaturesService, wyswigConfig, codeEditorOptions} from '../signatures.service';
import {Signature} from '../signatures.interface';
import {StartCheckingForInstallationInProgressAction} from '../../signature-installation-state/signature-installation-state.actions';
import {Store} from '@ngrx/store';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/edit/closetag';

@Component({
  selector: 'app-signature-edit',
  templateUrl: './signature-edit.component.html',
  styleUrls: ['./signature-edit.component.scss']
})
export class SignatureEditComponent implements OnInit {

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    content: new UntypedFormControl(''),
    addonBannerId: new UntypedFormControl(''),
    addonAdditionalBannerId: new UntypedFormControl(''),
    addonLinkId: new UntypedFormControl(''),
    addonDisclaimerId: new UntypedFormControl(''),
    userAvatarWidth: new UntypedFormControl(null, Validators.min(0)),
    userAvatarHeight: new UntypedFormControl(null, Validators.min(0)),
    userAvatarBorderRadius: new UntypedFormControl(null, [Validators.min(0), Validators.max(50)]),
    phoneLabel: new UntypedFormControl(''),
    mobileLabel: new UntypedFormControl(''),
    emailLabel: new UntypedFormControl(''),
  });

  footer: Signature;
  updating: boolean;
  config = wyswigConfig;
  sanitizedContent: SafeHtml;
  codeEditorOptions = codeEditorOptions;

  activeEditorTab = 0;
  activeSection: 'addons' | 'dynamicVariables' | null = null;
  addonsLoaded = false;

  constructor(
    private route: ActivatedRoute,
    private service: SignaturesService,
    private toast: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.updatePreview();
    this.form.get('content').valueChanges.subscribe(() => this.updatePreview());

    this.service.byId(this.route.snapshot.params.id).toPromise().then((f: Signature) => {
      this.footer = f;

      this.form.patchValue({
        name: f.name,
        content: f.content,
        addonBannerId: f.addonBannerId,
        addonAdditionalBannerId: f.addonAdditionalBannerId,
        addonDisclaimerId: f.addonDisclaimerId,
        addonLinkId: f.addonLinkId,
        userAvatarWidth: f.userAvatarWidth,
        userAvatarHeight: f.userAvatarHeight,
        userAvatarBorderRadius: f.userAvatarBorderRadius,
        phoneLabel: f.phoneLabel,
        mobileLabel: f.mobileLabel,
        emailLabel: f.emailLabel
      });

      this.addonsLoaded = true;
    });
  }

  updatePreview(): void {
    const rawHtml = this.form.get('content').value;
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
  }

  toggleSection(section: 'addons' | 'dynamicVariables'): void {
    this.activeSection = this.activeSection === section ? null : section;
  }

  save(installSignature = false) {
    this.updating = true;
    this.service.update(this.route.snapshot.params.id, this.form.getRawValue(), installSignature).toPromise()
      .then(() => this.toast.success(this.translate.instant('footers.updated')))
      .then(() => {
        if (!installSignature || (this.footer.numberOfInstallations && this.footer.numberOfInstallations > 0)) {
          this.goToSignatures();
        } else {
          this.goToSignatureDetails();
        }
      })
      .finally(() => this.updating = false);
  }

  goToSignatures = () => {
    this.router.navigate(['signatures'], {fragment: this.route.snapshot.params.id});
  };

  goToSignatureDetails = () => {
    this.router.navigate(['signatures', this.route.snapshot.params.id]);
  };

  saveAndInstall() {
    if ((this.footer.numberOfInstallations && this.footer.numberOfInstallations > 0) ||
        (this.footer.numberOfAliases && this.footer.numberOfAliases > 0)) {
      this.store.dispatch(new StartCheckingForInstallationInProgressAction());
    }
    this.save(true);
  }
}
