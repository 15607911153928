<div class="logo-container">
  <div class="text-center">
    <img src="/assets/img/logo_gSignature.svg" alt="..." width="300px">
  </div>
  <div class="text-center">
    <h1 class="display-5 mb-2 header">{{'login.title' | translate}}</h1>
    <button class="btn btn-lg btn-primary mt-3 w-25" [disabled]="redirecting"
            (click)="loginWithGoogle()">{{(redirecting ? 'login.redirecting' : 'login.google') | translate }}
    </button>
  </div>
</div>
