import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, forkJoin, map, Observable} from 'rxjs';
import {PaginatedData} from '../shared/model/table';
import {
  ChangeExcludedFromSignaturesParams, CurrentUserResponse,
  Employee, EmployeeDataImportResult, EmployeeDataImportVerificationResult,
  EmployeeListItem,
  EmployeeListSearchParams,
  EmployeeRequest, SingleEmployeeResponse
} from './employees.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  private refreshEmployeesSubject = new BehaviorSubject<void>(undefined);
  public refreshEmployees$ = this.refreshEmployeesSubject.asObservable();

  constructor(private http: HttpClient) {}

  triggerRefreshEmployees(): void {
    this.refreshEmployeesSubject.next();
  }

  getEmployees(
    { pager, ...params }: EmployeeListSearchParams,
    includeAliases: boolean = false
  ): Observable<PaginatedData<EmployeeListItem>> {
    const httpParams: any = {
      page: String(pager.page),
      limit: String(pager.limit),
      ...params,
    };

    if (includeAliases) {
      httpParams.aliases = 'true';
    }

    return this.http.get<PaginatedData<EmployeeListItem>>('/employees', {
      params: httpParams,
    });
  }

  getAllEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>('/employees/all');
  }

  getAllEmployeesWithAliases(): Observable<{ employee: EmployeeListItem, aliases: EmployeeListItem[] }[]> {
    const employees$ = this.http.get<PaginatedData<EmployeeListItem>>('/employees');
    const aliases$ = this.http.get<PaginatedData<EmployeeListItem>>('/employees', {
      params: { aliases: 'true' }
    });

    return forkJoin([employees$, aliases$]).pipe(
      map(([employeesResponse, aliasesResponse]) => {
        const employees = employeesResponse.data;
        const aliases = aliasesResponse.data.map(alias => ({
          ...alias,
          isAlias: true
        }));

        const groupedEmployees = employees.map(employee => {
          return {
            employee,
            aliases: aliases.filter(alias => alias.fullName === employee.fullName)
          };
        });

        return groupedEmployees;
      })
    );
  }

  excludeAllEmployees(filters: EmployeeListSearchParams, exclude: boolean): Observable<void> {
    return this.http.post<void>(`/employees/exclude-all`, { filters, exclude });
  }

  getNotExcludedEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>('/employees/not-excluded');
  }

  getNotExcludedEmployeesWithAliases(): Observable<Employee[]> {
    return this.http.get<Employee[]>('/employees/not-excluded-with-aliases');
  }

  changeExcludedFromSignatures(payload: ChangeExcludedFromSignaturesParams): Observable<any> {
    return this.http.put(`/employees/${payload.id}`, {excludedFromSignatures: payload.excludedFromSignatures});
  }

  sendEmployeeRequest(payload: EmployeeRequest): Observable<any> {
    return this.http.post(`/employees/invite`, payload);
  }

  getEmployee(id: string): Observable<SingleEmployeeResponse> {
    return this.http.get<SingleEmployeeResponse>(`/employees/${id}`);
  }

  updateEmployee(payload: Employee): Observable<any> {
    return this.http.put(`/employees/${payload.id}`, payload);
  }

  updateEmployeeAndInstallSignature(payload: Employee): Observable<any> {
    return this.http.put(`/user`, payload);
  }

  removeSignatureTemplate(id: string, isAlias: boolean): Observable<void> {
    const params = new HttpParams().set('isAlias', isAlias.toString());
    return this.http.delete<void>(`/employees/${id}/signature-remove`, { params });
  }

  syncGoogleEmployees(): Observable<any> {
    return this.http.post('/google-employees-sync', {});
  }

  getCurrentUser(): Observable<CurrentUserResponse> {
    return this.http.get<CurrentUserResponse>(`/user`);
  }

  validateImportData(file: File): Observable<EmployeeDataImportVerificationResult> {
    const formData = new FormData();
    formData.append('data', file);
    return this.http.post('/employees/import/validate', formData);
  }

  importEmployeeData(file: File, installSignature: boolean): Observable<EmployeeDataImportResult> {
    const formData = new FormData();
    formData.append('data', file);
    formData.append('installSignature', String(installSignature));
    return this.http.post<EmployeeDataImportResult>('/employees/import/perform', formData);
  }


  availableColumns(): Observable<string[]> {
    return this.http.get<string[]>('/employees/import/columns');
  }

  downloadAllSignatures(token?: string): Observable<Blob> {
    const headers = token ? { 'x-api-token': token } : {};
    return this.http.get('/employees/signatures/download', { headers, responseType: 'blob' });
  }

  importLocalEmployees(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<void>('/employees/local-import', formData);
  }
}
