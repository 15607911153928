import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-local-import-dialog',
  templateUrl: './employee-local-import-dialog.component.html',
  styleUrls: ['./employee-local-import-dialog.component.scss']
})
export class EmployeeLocalImportDialogComponent {
  public fileName = '';
  public selectedFile: File | null = null;

  constructor(private readonly dialogRef: MatDialogRef<EmployeeLocalImportDialogComponent>) {}

  public onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      this.selectedFile = fileInput.files[0];
      this.fileName = this.selectedFile.name;
    }
  }

  public triggerFileInput() {
    document.querySelector<HTMLInputElement>('.file-input')?.click();
  }

  public onImport() {
    if (this.selectedFile) {
      this.dialogRef.close({ file: this.selectedFile });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
