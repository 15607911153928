<div class="create-api">
  <h1 mat-dialog-title>
    {{ 'settings.api.createToken' | translate }}
    <button mat-icon-button class="close-button" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div mat-dialog-content>
    <app-loading *ngIf="isLoading"></app-loading>

    <form [formGroup]="form" *ngIf="!isLoading && !createdToken">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.api.tokenName' | translate }}</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <div mat-dialog-actions class="create-action" *ngIf="!isLoading && !createdToken">
        <button mat-button (click)="onCancel()">{{ 'cancel' | translate }}</button>
        <button mat-raised-button color="primary" (click)="onCreate()" [disabled]="form.invalid">
          {{ 'save' | translate }}
        </button>
      </div>
    </form>

    <div *ngIf="!isLoading && createdToken" class="token-display">
      <mat-form-field appearance="fill" class="token-field">
        <mat-label>{{ 'settings.api.generatedToken' | translate }}</mat-label>
        <input matInput [value]="createdToken.token" readonly>
        <button mat-icon-button matSuffix color="primary" (click)="copyTokenToClipboard()" [disabled]="copied">
          <mat-icon>{{ copied ? 'check' : 'content_copy' }}</mat-icon>
        </button>
      </mat-form-field>
      <p>{{ 'settings.api.generatedTokenWarning' | translate }}</p>
    </div>
  </div>
</div>
