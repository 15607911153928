import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeLocalImportDialogComponent } from './employee-local-import-dialog/employee-local-import-dialog.component';
import { ToastrTranslatedService } from '../../shared/toastr-translated.service';
import { EmployeesService } from '../employees.service';

@Component({
  selector: 'app-employee-local-import',
  templateUrl: './employee-local-import.component.html',
  styleUrls: ['./employee-local-import.component.scss']
})
export class EmployeeLocalImportComponent {
  constructor(
    private readonly dialog: MatDialog,
    private readonly toastr: ToastrTranslatedService,
    private readonly employeesService: EmployeesService
  ) {}

  public openDialog() {
    this.dialog.open(EmployeeLocalImportDialogComponent)
      .afterClosed()
      .subscribe((result?: { file: File }) => {
        if (result) {
          this.importLocalFile(result.file);
        }
      });
  }

  private importLocalFile(file: File) {
    this.employeesService.importLocalEmployees(file)
      .subscribe(() => {
        this.toastr.success('employee.localImport.success');
        this.employeesService.triggerRefreshEmployees();
      }, () => {
        this.toastr.error('employee.localImport.error');
      });
  }
}
