export interface Signature {
  id: string;
  name: string;
  content: string;
  numberOfInstallations: number;
  numberOfAliases: number;
  addonBannerId: string | undefined;
  addonAdditionalBannerId: string | undefined;
  addonLinkId: string | undefined;
  addonDisclaimerId: string | undefined;
  defaultForNewEmployee: boolean | undefined;
  userAvatarWidth?: number | undefined;
  userAvatarHeight?: number | undefined;
  userAvatarBorderRadius?: number | undefined;
  phoneLabel?: string;
  mobileLabel?: string;
  emailLabel?: string;
}

export interface ChangeSignatureObject {
  id: string;
  body: string;
  parsedBody: string;
}

export interface AddSignatureObject {
  id: string;
  body: string;
  content: string;
}

export interface SignaturesState {
  signatures: Signature[];
}

export interface Template {
  id: string;
  name: string;
  content: string;
  domain: string;
  created: number;
}

export interface SaveSignatureRequest {
  content: string;
  addonLinkId?: string;
  addonBannerId?: string;
  addonAdditionalBannerId?: string;
  addonDisclaimerId: string;
  userAvatarWidth?: number | undefined;
  userAvatarHeight?: number | undefined;
  userAvatarBorderRadius?: number | undefined;
  phoneLabel?: string;
  mobileLabel?: string;
  emailLabel?: string;
}

export interface SignaturePredefined {
  id: string;
  name: string;
  content: string;
  placeholderVariables: Record<string, string>;
}

export interface SaveTemplatePredefined {
  name: string;
  content: string;
  placeholderVariables: Record<string, string>;
}

export enum SetForAll {
  EMPLOYEES = 'employees',
  ALIASES = 'aliases',
  EMPLOYEES_AND_ALIASES = 'employeesAndAliases',
}
