<div class="billing-settings">
    <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
  
      <div class="section-label">
        {{ 'settings.billing.yourPlan' | translate }}
      </div>
  
      <mat-divider></mat-divider>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.planName' | translate }}</mat-label>
        <mat-select formControlName="planName">
          <mat-option *ngFor="let plan of plans" [value]="plan">{{ plan }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <div class="price-row">
        <mat-form-field appearance="fill" class="price-field">
          <mat-label>{{ 'settings.billing.price' | translate }}</mat-label>
          <input matInput formControlName="price" type="number" step="0.01" min="0">
        </mat-form-field>
      
        <mat-form-field appearance="fill" class="currency-field">
          <mat-label>{{ 'settings.billing.currency' | translate }}</mat-label>
          <mat-select formControlName="currency">
            <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.planId' | translate }}</mat-label>
        <input matInput formControlName="planId" disabled>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.subscriptionStartDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="subscriptionStartDate">
        <mat-hint>YYYY-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.lastInvoiceUserCount' | translate }}</mat-label>
        <input matInput formControlName="lastInvoiceUserCount">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.currentUserCount' | translate }}</mat-label>
        <input matInput formControlName="currentUserCount">
      </mat-form-field>
  
      <div class="section-label">
        {{ 'settings.billing.contactInfo' | translate }}
      </div>
  
      <mat-divider></mat-divider>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.contactName' | translate }}</mat-label>
        <input matInput formControlName="contactName">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.contactPhone' | translate }}</mat-label>
        <input matInput formControlName="contactPhone">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.contactEmail' | translate }}</mat-label>
        <input matInput formControlName="contactEmail">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.companyName' | translate }}</mat-label>
        <input matInput formControlName="companyName">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.country' | translate }}</mat-label>
        <input matInput formControlName="country">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.street' | translate }}</mat-label>
        <input matInput formControlName="street">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.streetNumber' | translate }}</mat-label>
        <input matInput formControlName="streetNumber">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.city' | translate }}</mat-label>
        <input matInput formControlName="city">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.postalCode' | translate }}</mat-label>
        <input matInput formControlName="postalCode">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.state' | translate }}</mat-label>
        <input matInput formControlName="state">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.vatNumber' | translate }}</mat-label>
        <input matInput formControlName="vatNumber">
      </mat-form-field>
  
      <div class="section-label">
        {{ 'settings.billing.paymentMethod' | translate }}
      </div>
  
      <mat-divider></mat-divider>
  
      <mat-radio-group formControlName="paymentMethod">
        <mat-radio-button value="invoice">{{ 'settings.billing.invoice' | translate }}</mat-radio-button>
        <div class="payment-description">{{ 'settings.billing.invoiceDescription' | translate }}</div>
        <mat-radio-button 
          value="card" 
          [disabled]="!auth.isSuperAdmin">
          {{ 'settings.billing.card' | translate }}
      </mat-radio-button>
      </mat-radio-group>
  
      <div class="actions">
        <button mat-raised-button type="submit">{{ 'save' | translate }}</button>
      </div>

      <div class="monthly-stats">
        <h3>{{ 'settings.billing.monthlyStats' | translate }}</h3>
        <div class="monthly-stats-table-container">
          <table mat-table [dataSource]="monthlyStats">
      
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.lastDayOfMonth' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="numberOfEmployees">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
            </ng-container>
      
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.price' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>    
  
    </form>
  </div>
  