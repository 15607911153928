import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SignaturesService} from '../signatures.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../users/users.service';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {AuthService} from '../../auth/auth.service';
import {Store} from '@ngrx/store';
import {BannersService} from '../../addons/banners/banners.service';
import {LinksService} from '../../addons/links/links.service';
import {DisclaimersService} from '../../addons/disclaimers/disclaimers.service';
import {SubmitUserComponent} from '../submit-user/submit-user.component';
import {StartCheckingForInstallationInProgressAction} from '../../signature-installation-state/signature-installation-state.actions';
import {SetForAllDialogComponent} from './set-for-all-dialog/set-for-all-dialog.component';
import {SetForAll} from '../signatures.interface';

@Component({
  selector: 'app-signature-details',
  templateUrl: './signature-details.component.html',
  styleUrls: ['./signature-details.component.scss']
})
export class SignatureDetailsComponent implements OnInit {
  @ViewChild(SubmitUserComponent) submitUserComponent: SubmitUserComponent;
  @ViewChild('iframePreview') iframe: ElementRef;
  preview;
  footer;
  updating;
  deleting;

  constructor(
    private service: SignaturesService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private readonly store: Store,
    private translate: TranslateService,
    private dialog: MatDialog,
    private bannersService: BannersService,
    private linksService: LinksService,
    private disclaimersService: DisclaimersService
  ) {}

  ngOnInit(): void {
    this.service
      .byId(this.route.snapshot.params.id)
      .toPromise()
      .then(async (f: any) => {
        this.footer = f;
        const content = await this.generatePreview();
        this.writeToFrame(content);
      });
  }

  updateInstallationStatus(): void {
    this.service.byId(this.footer.id).toPromise().then((f: any) => {
      this.footer.numberOfInstallations = f.numberOfInstallations;
      this.footer.numberOfAliases = f.numberOfAliases;
    });
  }

  setForAll = () => {
    const dialogRef = this.dialog.open(SetForAllDialogComponent, {
        data: {
          selectedOption: SetForAll.EMPLOYEES,
        },
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            const body = { all: result };
            this.assign(body);
        }
    });
};

  setForUser = (value: any) => this.assign({...value});
  assignToGroup = (group?: string) => this.assign({group});
  assignToGroupWithAlias = (group: { group: string, alias: string | string[] }) => this.assign(group);
  assignToUnit = (unit?: string) => this.assign({unit});

  assign(body): void {
    this.store.dispatch(new StartCheckingForInstallationInProgressAction());
    this.updating = true;
    this.userService.updateAll({ id: this.route.snapshot.params.id, ...body }).toPromise()
      .then(() => {
        this.toastr.success(this.translate.instant('footers.assigned'));
        if (this.submitUserComponent) {
          this.submitUserComponent.clearSelection();
        }
        this.updateInstallationStatus();
      })
      .finally(() => this.updating = false);
  }

  delete() {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('footers.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.deleting = true;
            this.service.delete(this.route.snapshot.params.id).toPromise()
              .then(() => this.toastr.success(this.translate.instant('footers.deleted')))
              .then(() => this.goToSignatures())
              .finally(() => this.deleting = false);
          }
        }
      );
  }

  async generatePreview(): Promise<string> {
    const addonKeys = [
      { key: 'addonLinkId', serviceMethod: this.linksService.getLinkById.bind(this.linksService) },
      { key: 'addonBannerId', serviceMethod: this.bannersService.getBannerById.bind(this.bannersService) },
      { key: 'addonAdditionalBannerId', serviceMethod: this.bannersService.getBannerById.bind(this.bannersService) },
      { key: 'addonDisclaimerId', serviceMethod: this.disclaimersService.getDisclaimerById.bind(this.disclaimersService) },
    ];
  
    const addonHtml = await Promise.all(addonKeys.map(async ({ key, serviceMethod }) => {
      const addonId = this.footer?.[key];
      if (addonId) {
        const addon = await serviceMethod(addonId).toPromise();
        return this.getAddons(addon, key);
      }
      return '';
    }));
  
    return [this.footer.content, ...addonHtml].join('');
  }
  
  
  private getAddons(addon: any, addonKey: string): string {
    const addons: { [key: string]: (addon: any) => string } = {
      addonLinkId: (link) => `<a href="${link.url}">${link.text}</a>`,
      addonBannerId: (banner) => banner.htmlContent,
      addonAdditionalBannerId: (banner) => banner.htmlContent,
      addonDisclaimerId: (disclaimer) => disclaimer.htmlContent,
    };
  
    return addons[addonKey]?.(addon) ?? '';
  }

  writeToFrame(content) {
    const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    doc.open();
    doc.write(content);
    doc.close();
    this.preview = true;
  }

  setForMe() {
    this.assign({email: this.auth.appUser.email});
  }

  goToSignatures = () => {
    this.router.navigate(['signatures']);
  };
}
